$(document).ready(function(){
    const owlOne = $('#owlOne .owl-carousel'),
        owlTwo = $('#owlTwo .owl-carousel');

    if ( owlOne ) {
        owlOne.owlCarousel({
            loop: true,
            margin: 8,
            autoplay:true,
            autoplayTimeout:5000,
            smartSpeed:1500,
            responsive:{
                0:{
                    items: 1
                },
                992:{
                    items:3,
                    nav:false
                }
            }
        });
        $( '#owlOne .s-boxes-footer a:last-of-type' ).click(function(e) {
            e.preventDefault();
            owlOne.trigger('next.owl.carousel');
        });
        $( '#owlOne .s-boxes-footer a:first-of-type' ).click(function(e) {
            e.preventDefault();
            owlOne.trigger('prev.owl.carousel', [300]);
        });
    }

    if ( owlTwo ) {
        owlTwo.owlCarousel({
            loop: true,
            margin: 8,
            responsive:{
                0:{
                    items: 1
                },
                992:{
                    items:3,
                    nav:false
                }
            }
        });
        $( '#owlTwo .s-boxes-footer a:last-of-type' ).click(function(e) {
            e.preventDefault();
            owlTwo.trigger('next.owl.carousel');
        });
        $( '#owlTwo .s-boxes-footer a:first-of-type' ).click(function(e) {
            e.preventDefault();
            owlTwo.trigger('prev.owl.carousel', [300]);
        });
    }
});



